<template>
  <b-modal
    id="question-modal"
    ref="question-modal"
    :title="type == 'edit' ? 'Cập nhật câu hỏi ' : 'Tạo câu hỏi'"
    size="xl"
    :hide-header-close="true"
    :no-close-on-backdrop="true"
    @cancel="resetModal"
    @hide="resetModal"
  >
    <b-overlay :show="loading">
      <b-container fluid class="p-0">
        <form ref="form" @submit.stop.prevent="handleSubmit">
          <b-row>
            <b-col>
              <basic-input
                label="Câu hỏi"
                placeholder="Câu hỏi"
                name="question"
                :required="true"
                :value.sync="form.question"
                data-vv-as="Câu hỏi"
                v-validate="'required'"
                :state="validateState('question')"
                :invalidFeedback="errors.first('question')"
              ></basic-input>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <basic-text-editors
                label="Câu trả lời"
                placeholder="Câu trả lời"
                name="answer"
                :required="true"
                :value.sync="form.answer"
                :toolbarOptions="['link', 'image', 'video']"
                data-vv-as="Câu trả lời"
                v-validate="'required'"
                :state="validateState('answer')"
                :invalidFeedback="errors.first('answer')"
              >
              </basic-text-editors>
            </b-col>
          </b-row>
        </form>
      </b-container>
    </b-overlay>
    <template #modal-footer>
      <div class="w-100 d-flex align-items-center justify-content-end">
        <b-button
          class="btn btn-plain ml-2"
          href="#"
          @click.stop="handleCancel"
          tabindex="0"
        >
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Basic/save.svg" />
          </span>
          Huỷ
        </b-button>
        <b-button
          class="btn btn-success ml-2"
          href="#"
          @click.stop="handelValidation"
          tabindex="0"
        >
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Basic/save.svg" />
          </span>
          Cập nhật
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: 'Modal',
  components: {},
  props: {
    popupType: {
      type: String,
      default: 'create',
    },
    id: {
      type: String,
      default: null,
    },
  },
  computed: {
    type() {
      return this.popupType;
    },
  },
  watch: {
    id: {
      deep: true,
      handler(newVal) {
        if (newVal) {
          this.loadData(newVal);
        }
      },
    },
    form: {
      deep: true,
      handler(newVal) {
        if (
          Object.keys(newVal).length &&
          this.initialFrom !== JSON.stringify(newVal)
        ) {
          this.isChange = true;
        }
      },
    },
  },
  data() {
    return {
      detail: {},
      validationState: {},
      error: {},
      form: {},
      loading: false,
      isChange: null,
      initialFrom: null,
    };
  },

  methods: {
    async loadData(id) {
      this.loading = true;
      try {
        let { data } = await this.$api.get(`Profile/Instruction/${id}`);
        this.form = data;
        this.initialFrom = JSON.stringify(data);
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi!',
          msg: error,
        });
      } finally {
        this.loading = false;
      }
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.errors.has(ref);
      }
      return null;
    },
    handelValidation() {
      this.$validator.validateAll().then((result) => {
        if (!result) {
          return;
        }
        this.handleSubmit();
      });
    },
    async handleSubmit() {
      try {
        if (this.type == 'edit') {
          await this.$api.put('Profile/Instruction/Input', this.form);
        } else {
          await this.$api.post('Profile/Instruction/Input', this.form);
        }

        this.$bvModal.hide('question-modal');
        this.$emit('loadData');
        this.$toastr.s({
          title: 'Thành công !',
          msg: this.type == 'edit' ? 'Cập nhật thành công' : 'Tạo thành công',
        });
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error,
        });
      }
    },

    resetModal() {
      this.form = {};
      this.initialFrom = {};
      this.isChange = false;
      this.$emit('update:id', null);
    },
    async handleConfirm() {
      return await this.$swal({
        title: 'Xác nhận?',
        text: `Dữ liệu thay đổi chưa được lưu, xác nhận thoát?`,
        icon: 'warning',
        buttons: {
          cancel: {
            text: 'Huỷ',
            value: false,
            visible: true,
            className: '',
            closeModal: true,
          },
          confirm: {
            text: 'Xác nhận',
            value: true,
            visible: true,
            className: '',
            closeModal: true,
          },
        },
      });
    },
    async handleCancel() {
      if (this.isChange) {
        let confirm = await this.handleConfirm();

        confirm == true ? this.$bvModal.hide('question-modal') : null;
      } else {
        this.$bvModal.hide('question-modal');
      }
    },
  },
};
</script>

<style lang="scss"></style>
